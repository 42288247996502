import { useMemo, useState } from 'react'
import { Paper, TableContainer, Table, TableBody, TableRow, TableCell, Link, Button, CircularProgress } from '@mui/material';
import { useFetch } from '../../../hooks/useFetch';
import BoxHeader from '../../../common/Components/BoxHeader'
import { ILeg } from '@aviation/catering-flightservice-sdk';
import PaxListDialog from './PaxListDialog';
import { useAuth } from '../../../hooks/useAuth';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';

export interface ILegInfoRow {
    propertyName: string;
    propertyValue?: string;
    link?: boolean;
    action?: () => void;
}

export interface ICateringRouteLeg {
    Al: string;
    Nr: string;
    Dep: string;
    Dest: string;
    DepDate: string;
    FlightDate: string
}

export interface ILegInfoContainerProps {
    currentLeg : ICateringRouteLeg;
}

export function LegInfoContainer(props : ILegInfoContainerProps) {
    const { data } = useFetch<ILeg>(`/api/flights/byleg/${props.currentLeg.FlightDate}/${props.currentLeg.Al}/${props.currentLeg.Nr}/${props.currentLeg.Dep}/${props.currentLeg.Dest}`, (response : any) => response);
    const [showPaxList, setShowPaxList] = useState(false);
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    
    const onShowPax = () => {
        setShowPaxList(true);
    }

    const syncPax = async() => {
        setLoading(true);

        try {
            let payload = {
                Al: data?.Al,
                No: data?.No,
                Dep: data?.Dep,
                Dest: data?.Dest,
                DepDate: data?.DepDate,
                DepLocalDate: data?.DepLocalDate,
                FlightDate: data?.FlightDate
            }

            const init = {
                body: payload,
                headers: {}
            };

            let url = `/api/newskies/runsync`;
            if(payload.Al === 'BLX')
                url = `/api/paxport/runsync`

            let response = await API.post('api', url, init);

            if(response?.Message) {
                toast.success(response?.Message ?? '');
            } else {
                toast.error('Newskies synchronization could not be started due to an error.');
            }
            setLoading(false);
        } catch(e) {
            console.error(e);
            setLoading(false);
            toast.error('Newskies synchronization could not be started due to an error.');
            return false;
        } 
    }

    const rows = useMemo(() => {
        const result : ILegInfoRow[] = [];

        result.push({ propertyName: 'Flight Number', propertyValue: `${data?.Al ?? ''}${data?.No ?? ''}`});
        result.push({ propertyName: 'Departure Date', propertyValue: `${data?.DepDate ? new Date(data.DepDate).toLocaleString() : ''}`});
        result.push({ propertyName: 'Local Date', propertyValue: `${data?.DepLocalDate ? new Date(data.DepLocalDate).toLocaleString() : ''}`});
        result.push({ propertyName: 'Departure', propertyValue: `${data?.Dep ?? ''}`});
        result.push({ propertyName: 'Destination', propertyValue: `${data?.Dest ?? ''}`});
        result.push({ propertyName: 'Aircraft', propertyValue: `${data?.AcReg ?? ''}`});
        result.push({ propertyName: 'Aircraft Type', propertyValue: `${data?.AcType ?? ''}`});
        result.push({ propertyName: 'Pax', propertyValue: `${data?.PaxTotal ?? '0'}/${data?.PaxChildren ?? '0'}+${data?.PaxInfants ?? '0'}`, link: auth.isAdministrator || auth.isSuperAdmin, action: onShowPax});

        return result;
    }, [data, auth]);

    

    return (
        <Paper sx={{ p: 2, minHeight:'316px' }} elevation={3}>
            <BoxHeader title='Flight Details'>
                {
                    (auth.isSuperAdmin || auth.isAdministrator) && (
                    <Button variant="contained" onClick={() => syncPax()} disabled={loading}>
                        { !loading && 'Sync Pax' }
                        { loading && <CircularProgress size="25px" />}
                    </Button>
                    )
                }
                
            </BoxHeader>
            <TableContainer>
                <Table size='small'>
                    <TableBody>
                        { (rows ?? []).map((row) => (
                            <TableRow key={row.propertyName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                {row.propertyName}
                                </TableCell>
                                <TableCell align="right">{ (row.link ?? false) ? <Link onClick={() => row.action!() }>{row.propertyValue ?? ''}</Link> : row.propertyValue ?? ''}</TableCell>
                            </TableRow>    
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            { showPaxList && <PaxListDialog cancelAction={() => setShowPaxList(false)} airline={props.currentLeg.Al} flightNo={props.currentLeg.Nr} flightDate={props.currentLeg.FlightDate} dep={props.currentLeg.Dep} dest={props.currentLeg.Dest} /> }
        </Paper>
    )
}

export default LegInfoContainer;