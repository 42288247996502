import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowParams, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid-premium';
import { IconButton, SvgIcon } from '@mui/material';
import { Status, useFetch } from '../../hooks/useFetch';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DynamoDbObject } from '@aviation/catering-common';
import { CATERING_ROUTES } from '../../common/paths';
import { CLIENT_CODE } from '../../common/constants';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { VipAlertItem } from '@aviation/vip-alert-service-sdk';
import dayjs from 'dayjs';
import VipAlertChip from './VipAlertChip';

export enum StatusFilter {
    Completed = 0,
    Incompleted = 1,
    Ignored = 2,
    All = 3
}

export interface IVipAlertDashboardProps {
    dateFrom?: string;
    dateTo?: string;
}

function VipAlertDashboard(props : IVipAlertDashboardProps) {
    const apiRef = useGridApiRef();
    const location = useLocation();
    const { clientCode } = useParams();
    const rootPath = `/${clientCode || CLIENT_CODE}`;
    const navigate = useNavigate();
    
    const { status, data = [] } = useFetch<Array<VipAlertItem & DynamoDbObject>>(`/api/vipalert/${clientCode}/from/${props.dateFrom}/to/${props.dateTo}`, (response) => response, 0, true);

    const navigateTo = (row : any) => {
        let gridState = apiRef.current.exportState();
        localStorage.setItem(`GRID_${location.pathname}`, JSON.stringify(gridState));
        navigate(`${rootPath}/${CATERING_ROUTES}/${encodeURIComponent(row.CateringRouteId)}`)
    }

    const columns: GridColDef[] = [
        {
            field: "action",
            headerName: "",
            sortable: false,
            width:55,
            renderCell: ({ row }: Partial<GridRowParams>) =>
              (!row.Leg ? 
                  <IconButton color='primary' onClick={() => navigateTo(row)}>
                      <SvgIcon component={EditIcon} inheritViewBox />
                  </IconButton> : null),
          },

        { field: 'ThresholdDashOn', headerName: ' ', minWidth: 150, renderCell: (params: GridRenderCellParams) => (<VipAlertChip item={params.row} />)},
        { field: 'ServiceType', headerName: 'Service Type', minWidth: 120, editable: false },
        { field: 'DepDate', headerName: 'Date', minWidth: 100, type: 'string', editable: false, valueGetter: (params : GridValueGetterParams) => { return (params.value !== undefined ? dayjs(params.value).format('YYYY-MM-DD') : '')} },
        { field: 'Route', headerName: 'Route', minWidth: 150, editable: false },
        { field: 'FlightNumbers', headerName: 'Flights', minWidth: 200, editable: false },
        { field: 'AcReg', headerName: 'AC-Reg', minWidth: 150, editable: false },
        { field: 'Count', headerName: 'Amount', minWidth: 120, editable: false },
    ];

    const rowId = (item : VipAlertItem & DynamoDbObject) => {
        return `${item.PK}#${item.RK}`;
    }

    const aggregatedData = useMemo(() => {
        return data.map(o => {
            return {
                ...o,
                id: rowId(o),
                Route: o.Route?.Route,
                DepDate: o.Route?.FlightDate,
                FlightNumbers: o.Route?.FlightNumbers,
                CateringRouteId: o.Route?.CateringRouteId
            }
        })
    }, [data])

    return (

        <DataGridPremium
            apiRef={apiRef}
            autoHeight
            rows={aggregatedData}
            columns={columns}
            // pageSize={10}
            // rowsPerPageOptions={[10]}
            pagination
            getRowClassName={(params) => { return params.row.Leg === true ? `tui-grid-leg-row` : 'tui-grid-route-row'}}
            loading={status === Status.Fetching || status === Status.Idle}
            // error={error}
            groupingColDef={{
                headerName: ' ',
                valueGetter: (params: GridValueGetterParams) => '',
                width: 50,
                resizable: false,
                hideable: false,                
            }}
        />
    );
}

export default VipAlertDashboard;