import { Button, CircularProgress, Paper, Stack, Tab, Tabs, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import DemoChart from './DemoChart';
import { CustomTheme } from '../../theme/CustomTheme';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import PageHeader from '../../common/Components/PageHeader';
import CateringRouteGrid, { StatusFilter } from '../CateringRoutes/CateringRouteGrid';
import VipAlertDashboard from './VipAlertDashboard';
import { API } from 'aws-amplify';
import {toast} from "react-toastify";
import { useAuth } from '../../hooks/useAuth';

function Dashboard() {
    const { t } = useTranslation();
    const theme = useTheme<CustomTheme>();
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([dayjs(), dayjs().add(20, 'day')]);
    const [tabIndex, setTabIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const auth = useAuth();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const handleSync = async () => {
        setLoading(true);

        try {
            let response = await API.post('api', `/api/idps/runsync`, {});

            if(response?.Description) {
                toast.success(response?.Description ?? '');
            } else {
                toast.error('IDPS synchronization could not be started due to an error.');
            }
            setLoading(false);
        } catch(e) {
            console.error(e);
            setLoading(false);
            toast.error('IDPS synchronization could not be started due to an error.');
            return false;
        } 
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('Dashboard')}>
                <Stack direction='row' spacing={2}> 
                    { auth.isSuperAdmin && <Button sx={{ height: 40, width: 120 }} id="sync-button" variant="contained" disableElevation onClick={(e) => handleSync() } disabled={loading}>
                        {loading && <CircularProgress size="25px" />}
                        {!loading && 'IDPS Sync'}
                    </Button> }
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
                        <DateRangePicker value={dateRange} onChange={setDateRange} slotProps={{ textField: { size: 'small' } }} />
                    </LocalizationProvider>
                </Stack>
                    
                </PageHeader>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                    <DemoChart title="IDPS" subtitle="Synchronized Flights" height={150} color={theme.palette.info.main} />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                    <DemoChart title="Newskies" subtitle="Synchronized Meals" height={150} color={theme.palette.info.main} />
                </Paper>
            </Grid>
            
            {/* <Grid item xs={12} md={4}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                    <DemoChart title="Errors" height={150} color={theme.palette.error.main} />
                </Paper>
            </Grid> */}
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                <div style={{flexBasis:'100%', flexGrow:1}} />
                    <Tabs value={tabIndex} onChange={handleTabChange} sx={{marginLeft:2}}>
                        <Tab label="Incomplete Routes" />
                        <Tab label="VIP Alerts" />
                    </Tabs>
                    { tabIndex === 0 && <CateringRouteGrid showIncompleteRoutes={true} dateFrom={dateRange[0]?.format("YYYY-MM-DD")} dateTo={dateRange[1]?.format("YYYY-MM-DD")} statusFilter={StatusFilter.Incompleted} /> }
                    { tabIndex === 1 && <VipAlertDashboard dateFrom={dateRange[0]?.format("YYYY-MM-DD")} dateTo={dateRange[1]?.format("YYYY-MM-DD")} /> }
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Dashboard;